@import "styles/variables";
@import "styles/mixins";

.address {
  @include h5($colorDarkest);

  margin: 0.5rem 0 0 0;
  opacity: 0.5;
}

.preview {
  @include resetButton;

  width: 100%;
  height: 35rem;
  background-size: cover;
}

.full {
  display: block;
  width: 100rem;
}

.list {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  margin-top: 2rem;
}

.subheading {
  margin-top: 5rem;
  text-align: center;
}

.feature {
  @include h8($colorLightest);

  position: relative;
  padding: 5rem 5rem 5rem 25rem;
  background-color: $colorHighlightDark;
}

.icon {
  --logo-size: 13rem;
  position: absolute;
  top: calc(50% - var(--logo-size) / 2);
  left: 7rem;
  width: var(--logo-size);
  height: var(--logo-size);
  fill: $colorLightest;
}

@media (max-width: $adaptiveMax) {
  .preview {
    height: 20rem;
  }

  .full {
    display: block;
    margin: 0;
    width: 90vw;
  }

  .feature {
    padding: 5rem 5rem 5rem 20rem;
  }

  .icon {
    --logo-size: 11rem;
    left: 5rem;
  }
}

@media (max-width: $mobileMax) {
  .preview {
    height: 12rem;
  }

  .feature {
    padding: 12rem 3rem 3rem 3rem;
  }

  .icon {
    --logo-size: 9rem;
    top: 3rem;
    left: calc(50% - var(--logo-size) / 2);
  }
}
