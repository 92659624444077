@import "styles/variables";

.wrapper {
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--padding-container-h);
  padding-left: var(--padding-container-h);
  max-width: 144rem;
}

@media (max-width: $adaptiveMax) {
  .wrapper {
    padding-right: var(--padding-container-h);
    padding-left: var(--padding-container-h);
    max-width: 100%;
  }
}
