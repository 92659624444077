@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  transition-duration: $transitionDuration;
  opacity: 0;
  transition-property: opacity;
  pointer-events: none;
  z-index: -1;
  overflow: hidden auto;
}

.wrapperOpened {
  opacity: 1;
  pointer-events: all;
  z-index: 5;
}

.content {
  position: relative;
}

.button {
  @include resetButton;

  position: absolute;
  top: -5rem;
  right: -1rem;
  padding: 1rem;

  .icon {
    width: 3rem;
    fill: $colorHighlight;
  }
}

@media (max-width: $adaptiveMax) {
  .wrapper {
    position: fixed;
    padding: 0;
    height: 100vh;
  }
}
