@import "styles/variables";
@import "styles/mixins";

.logoWrapper {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 17rem;
  fill: $colorDarkest;
}

.logoLabel {
  @include small($colorDarkest);

  display: inline-block;
  margin-top: -2rem;
  font-size: 1rem;
}

@media (max-width: $adaptiveMax) {
  .logo {
    width: 14rem;
  }

  .logoLabel {
    margin-top: -1.5rem;
    font-size: 0.81rem;
  }
}
