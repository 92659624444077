@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-gap: 5rem;
}

.image {
  width: 100%;
  height: 50rem;
  object-fit: cover;
}

.descriptor {
  @include regular($colorDarkest);

  display: flex;
  flex-direction: column;
  margin: 2.5rem 0 0 0;
}

@media (max-width: $adaptiveMax) {
  .wrapper {
    grid-template-columns: 50% 1fr;
    grid-gap: 4rem;
  }

  .image {
    height: 60rem;
  }

  .descriptor {
    margin: 3.5rem 0 0 0;
  }
}

@media (max-width: $mobileMax) {
  .wrapper {
    display: block;
  }

  .image {
    height: 20rem;
  }

  .descriptor {
    margin: 2rem 0 0 0;
  }
}
