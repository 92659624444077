@import "styles/variables";
@import "styles/mixins";

.footer {
  background-color: $colorHighlightDark;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.logo {
  fill: $colorLightest;
  width: 9rem;
}

.year {
  @include regular($colorLightest);
}
