// screen sizes
$adaptiveMax: 1200px;
$mobileMax: 767px;

// colors
$colorLightest: rgb(255, 255, 255);
$colorHighlightLightest: #f7f8fB;
$colorHighlight: #ff5530;
$colorHighlightMedium: rgb(193, 188, 172);
$colorHighlightDark: rgb(92, 116, 87);
$colorDarkest: #464b56;
$colorRegular: rgb(151, 155, 141);
$colorShader: rgba(92, 116, 87, 0.4);


  // borders
  // shadows

  // misc
$retina__dpi: 144dpi;
$retina__dppx: 1.5dppx;
// transitions
$transitionDuration: 0.3s;

// spaces

body {
  :global {
    --padding-container-h: 2rem;

    @media (max-width: $mobileMax) {
      --padding-container-h: 1rem;
    }
  }
}
