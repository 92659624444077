@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
}

.slider {
  :global {
    .swiper-wrapper {
      @include resetList;
    }
  }
}

.button {
  @include resetButton;

  position: absolute;
  top: 8rem;
  right: calc(var(--padding-container-h) + 6rem);
  padding: 1rem;
  background-color: $colorShader;
  transition-duration: $transitionDuration;

  &:hover {
    background-color: $colorHighlightDark;

    .icon {
      fill: $colorLightest;
    }
  }

  &:active {
    .icon {
      transform: scale(1.1)
    }
  }
}

.buttonNext {
  right: var(--padding-container-h);
  left: auto;
  transform: rotate(180deg);
}

.buttonDisabled {
  opacity: 0.5;
  pointer-events: none;
}

.icon {
  width: 3rem;
  transition-duration: $transitionDuration;
}

@media (max-width: $adaptiveMax) {
  .button {
    top: 7rem;
    right: calc(var(--padding-container-h) + 10rem);
    padding: 2rem;
  }

  .buttonNext {
    right: var(--padding-container-h);
    left: auto;
  }
}

@media (max-width: $mobileMax) {
  .slider {
    margin-top: 10rem;
  }

  .button {
    top: 12rem;
    left: var(--padding-container-h);
    right: auto;
    padding: 2rem;
    width: 40%;
  }

  .buttonNext {
    right: var(--padding-container-h);
    left: auto;
  }
}
