@import "styles/variables";
@import "styles/mixins";

.wrapper {
  display: grid;
  grid-template-columns: 40% 1fr;
  grid-gap: 15rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.img {
  width: 100%;
  height: 30rem;
  object-fit: cover;
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.address,
.link {
  @include regular($colorDarkest);
}

.link {
  @include link;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }
}

@media (max-width: $adaptiveMax) {
  .wrapper {
    display: grid;
    grid-template-columns: 50% 1fr;
    grid-gap: 5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (max-width: $mobileMax) {
  .wrapper {
    display: block;
  }
}
