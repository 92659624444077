@import "styles/variables";
@import "styles/mixins";

.wrapper {
  position: relative;
  overflow: hidden;

  &:hover {
    .photo {
      filter: grayscale(0);
    }
  }
}

.rank {
  @include small($colorLightest);

  position: absolute;
  top: 39rem;
    transform: translateY(-100%);
  right: 0;
  padding: 0.5rem 2rem;
  width: 65%;
  text-align: center;
  z-index: 1;
}

.ceo {
  background-color: #9A275A;
}

.cm {
  background-color: #279a7d;
}

.md {
  background-color: #6f39b4;
}

.pm {
  background-color: #E36588;
}

.adm {
  color: $colorDarkest;
  background-color: #99EDCC;
}

.engineer {
  background-color: #9AC4F8;
}

.accountant {
  background-color: #daa520;
}

.fin {
  background-color: #b29700;
}

.manager {
  color: $colorDarkest;
  background-color: #fcf6b1;
}

.it {
  background-color: #083d77;
}

.law {
  background-color: #8a3033;
}

.td {
  background-color: #304f8a;
}

.sd {
  background-color: #cc6f44;
}

.photo {
  position: relative;
  width: 100%;
  height: 39rem;
  object-fit: cover;
  object-position: center;
  filter: grayscale(1);
  transition-duration: $transitionDuration;
}

.name {
  @include h7($colorDarkest);

  display: block;
  margin: 1rem 0 0 0;
}

.email {
  @include regular($colorDarkest);
  @include link;
}

@media (max-width: $adaptiveMax) {
  .rank {
    top: 39rem;
    transform: translateY(-100%);
    width: 100%;
  }

  .photo {
    height: 39rem;
    filter: none;
  }
}

@media (max-width: $mobileMax) {
  .rank {
    top: 30rem;
    transform: translateY(-100%);
    padding: 0.5rem 0.5rem;
    width: 100%;
  }

  .photo {
    height: 30rem;
  }
}
