@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: flex;
  align-items: center;
}

.item {
  &:not(:first-child) {
    margin-left: 3rem;
  }
}

.link {
  @include h5($colorDarkest);

  opacity: 0.7;
}

@media (max-width: $mobileMax) {
  .list {
    padding-top: 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .item {
    margin-top: 1.5rem;

    &:not(:first-child) {
      margin-left: 0;
    }
  }
}
