@import "styles/variables";
@import "styles/mixins";

.list {
  @include resetList;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 4rem 2rem;
}

.buttonMore {
  @include resetButton;
  @include h4($colorDarkest);

  display: block;
  margin: 5rem auto 0 auto;
  padding: 1rem 2rem;
  background-color: $colorShader;
  transition-duration: $transitionDuration;

  &:hover,
  &:active {
    color: $colorLightest;
    background-color: $colorHighlightDark;
  }

  &:active {
    opacity: 0.7;
  }
}

@media (max-width: $adaptiveMax) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: $mobileMax) {
  .list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem 1rem;
  }
}
