@import "styles/variables";
@import "styles/mixins";

.heading {
  margin: 0;
}

.h1 {
  @include h0($colorLightest);
}

.h2 {
  @include h1($colorLightest);
}

.h3 {
  @include h2($colorDarkest);
}

.h4 {
  @include h4($colorDarkest);
}

.headingDecorated {
  position: relative;
  display: inline-block;
  color: $colorDarkest;

  &::before {
    --extension: 1rem;
    content: "";
    position: absolute;
    bottom: -0.5rem;
    left: calc(-1 * var(--extension));
    width: calc(100% + 2 * var(--extension));
    height: 2rem;
    background-color: $colorHighlightDark;
    z-index: -1;
    opacity: 0.5;
  }
}

@media (max-width: $adaptiveMax) {
  .headingDecorated {
    &::before {
      --extension: 0.5rem;
      bottom: 0;
      height: 1.5rem;
    }
  }
}
