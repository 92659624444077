@import "styles/variables";
@import "styles/mixins";

.button {
  @include resetButton;
  @include h9-regular($colorDarkest);

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  background-size: cover;
  cursor: pointer;

  &:hover {
    .icon {
      :global {
        .circle__outer {
          stroke: $colorHighlight;
        }

        .circle__inner {
          fill: $colorHighlight;
        }

        .icon__play {
          fill: $colorDarkest;
        }
      }
    }
  }

  .icon {
    width: 5.7rem;

    :global {
      .circle__outer {
        stroke: $colorDarkest;
        transition-duration: 0.3s;
      }

      .circle__inner {
        fill: $colorDarkest;
        transition-duration: 0.3s;
      }

      .icon__play {
        fill: $colorHighlight;
        transition-duration: 0.3s;
      }
    }
  }

  .label {
    margin-top: 0.9rem;
  }
}

.button.buttonSquare {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 4rem;
    height: 4rem;
    background-color: $colorHighlightDark;
    transition-duration: 0.3s;
  }

  .iconSquare {
    position: absolute;
    bottom: calc(4rem - 5.7rem + 1.4rem / 2);
    right: calc(4rem - 5.7rem + 1.4rem / 2);

    :global {
      .circle__outer,
      .circle__inner {
        display: none;
      }
    }
  }

  &:hover {
    &::before {
      background-color: $colorHighlight;
    }
  }
}

.buttonMinified {
  width: 2.9rem;

  .icon {
    width: 100%;

    :global {
      .circle__outer {
        display: none;
      }

      .circle__inner {
        stroke: $colorHighlight;
        transition-duration: 0.3s;
      }
    }
  }
}
