@import "styles/variables";
@import "styles/mixins";

.intro {
  position: relative;
  padding-top: 5rem;

  .play {
    position: absolute;
    top: 0;
    right: 0;
    width: 40rem;
    height: 20rem;
    z-index: 3;
  }
}

.wrapper {
  position: relative;
  background-image: url("../../assets/images/main_banner-bg.jpg");
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorShader;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/icons/banner_shape.svg");
    background-size: cover;
    opacity: 0.7;
    z-index: 1;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: 50rem;
  text-align: center;
  z-index: 2;
}

.dots {
  position: absolute;
  top: -5rem;
  right: calc(50% - 45rem);
  width: 25rem;
  z-index: 1;
}

.video {
  display: block;
  width: 70vw;
}

.descriptor {
  @include h7($colorLightest);
}

@media (max-width: $adaptiveMax) {
  .section {
    overflow: hidden;

    .play {
      width: 35rem;
      height: 17rem;
    }
  }

  .container {
    min-height: 40rem;
  }

  .video {
    display: block;
    width: 90vw;
  }

  .dots {
    top: -12rem;
    right: -3rem;
    width: 25rem;
    z-index: 1;
  }
}

@media (max-width: $mobileMax) {
  .section {
    .intro {
      padding-top: 2rem;
    }

    .play {
      position: relative;
      margin-left: calc(-1 * var(--padding-container-h));
      width: calc(100% + 2 * var(--padding-container-h));
    }

    .dots {
      width: 20rem;
      z-index: 1;
    }
  }
}
