@import "styles/variables";
@import "styles/mixins";

.header {
  width: 100%;
  z-index: 2;
}

.contacts {
  padding: 1.5rem 0;
  background-color: $colorHighlightLightest;
}

.contactsWrapper {
  display: flex;
}

.phone,
.location,
.email {
  display: flex;
  align-items: center;
  margin: 0;
}

.link,
.location {
  @include regular($colorDarkest);
}

.link,
.email {
  @include link;
}

.email {
  margin-left: 2rem;
}

.location {
  margin-left: auto;
}

.icon {
  margin-right: 1rem;
  width: 2rem;
  fill: $colorDarkest;
  opacity: 0.5;
}

.wrapper {
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $colorLightest;
}

.nav {
  margin-left: auto;
}

.buttonCollapse {
  display: none
}

@media (max-width: $adaptiveMax) {
  .contacts {
    padding: 1rem 0;
  }
}

@media (max-width: $mobileMax) {
  .contacts {
    display: none;
  }

  .buttonCollapse {
    @include resetButton;

    display: inline-block;
    margin-left: auto;
  }

  .burger {
    display: block;
    width: 2.5rem;
    fill: $colorDarkest;
  }

  .wrapper {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .nav {
    margin-left: 0;
    width: 100%;
  }
}
