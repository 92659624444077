@import "styles/variables";

.wrapper {
  padding-top: 7rem;
  padding-bottom: 1rem;
}

.content {
  padding-top: 4rem;
}

@media (max-width: $mobileMax) {
  .wrapper {
    padding-top: 5rem;
  }

  .content {
    padding-top: 2rem;
  }
}
